
<template>
  <section class="footer-section" style="background-color: black; padding: 20px;">
    <div class="container">
      <div class="row">
        <!-- Left side: Links to pages -->
        <div class="col-md-6 footer-nav foot">
          <h2>Navigation</h2>
          <ul class="navbar-nav" style="color: white !important;">
          <li class="foot">
            <router-link to="/"  style="font-weight: bold;">Home</router-link>
          </li>
            <li class="nav-item">
            <router-link  to="/researchpage">Research</router-link>
          </li>
          <li class="nav-item">
            <router-link  to="/mentorshippage">UX Mentorship</router-link>
          </li>
          <li class="nav-item">
            <router-link  to="/workpage">Work</router-link>
          </li>
          <li class="nav-item">
            <router-link  to="/resumepage">Resume</router-link>
          </li>
          <li class="nav-item">
            <router-link  to="/blogpage">Blogs</router-link>
          </li>
          <li class="nav-item">
            <router-link  to="/contactpage">Contact</router-link>
          </li>
        </ul>
        </div>

        <!-- Right side: Contact information and social icons -->
        <div class="col-md-6 footer-contact">
          <h2>Contact</h2>
          <p>Email: <a href="mailto:bachateravi@gmail.com" class="foot">bachateravi@gmail.com</a></p>
          <p>Phone: <a href="tel:+917588697656" class="foot">07588697656</a></p>
          <div class="social-icons">
            <a href="https://www.instagram.com/" target="_blank" class="foot">
              <i class="fab fa-instagram"></i>
            </a>
            <a href="https://twitter.com/" target="_blank" class="foot">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="https://plus.google.com/" target="_blank" class="foot">
              <i class="fab fa-google-plus-g"></i>
            </a>
            <a href="https://www.linkedin.com/" target="_blank" class="foot">
              <i class="fab fa-linkedin-in"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FooterPage"
};
</script>

<style scoped>
/* Footer section styling */
.footer-section {
  padding: 20px;
  background-color: black;
  color: white;
}

.foot {
  color: white;
  text-decoration: none;
}

.foot:hover {
  text-decoration: underline;
}

/* Navigation styling */
.footer-nav h5 {
  margin-bottom: 10px;
}

.navbar-nav{
  color: white !important;
}
.nav-item{
  color: white !important;
}
.nav-list {
  list-style: none;
  padding: 0;
}

.nav-list li {
  margin-bottom: 10px;
}

/* Contact section styling */
.footer-contact h5 {
  margin-bottom: 10px;
}

.social-icons {
  margin-top: 15px;
}

.social-icons a {
  font-size: 24px;
  color: white;
  margin-right: 15px;
}

.social-icons a:hover {
  color: #999;
}
</style>

<!--
<template>
  <section class="footer-section" style="background-color: black; padding: 20px;">
    <div class="row">
   
      <div class="col-md-6" style="text-align: left; margin-left: 80px; font-size: large; color: white;">
        <a href="#work" class="foot">Work</a><br>
        <a href="#research" class="foot">Research</a><br>
        <a href="#mentorship" class="foot">UX Mentorship</a><br>
        <a href="#resume" class="foot">Resume</a><br>
        <a href="#contact" class="foot">Contact</a>
      </div>

     
      <div class="col-md-6" style="font-size: large; color: white;">
        <p>Email: <a href="mailto:bachateravi@gmail.com" class="foot">bachateravi@gmail.com</a></p>
        <p>Phone: <a href="tel:+917588697656" class="foot">07588697656</a></p>
        <div class="social-icons">
          <a href="https://www.instagram.com/" target="_blank" class="foot" style="margin-right: 15px;">
            <i class="fab fa-instagram"></i>
          </a>
          <a href="https://twitter.com/" target="_blank" class="foot" style="margin-right: 15px;">
            <i class="fab fa-twitter"></i>
          </a>
          <a href="https://plus.google.com/" target="_blank" class="foot" style="margin-right: 15px;">
            <i class="fab fa-google-plus-g"></i>
          </a>
          <a href="https://www.linkedin.com/" target="_blank" class="foot">
            <i class="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FooterPage"
};
</script>

<style scoped>
/* Add styles for footer here */
.footer-section {
  padding: 20px;
  background-color: black;
  color: white;
}

.foot {
  color: white;
  text-decoration: none;
}

.foot:hover {
  text-decoration: underline;
}

.social-icons a {
  font-size: 24px;
  color: white;
  margin-right: 15px;
}

.social-icons a:hover {
  color: #999;
}
</style>
-->