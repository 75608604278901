<template>
    <div class="case-study-page">
      <!-- Hero Section -->
      <section class="hero-section">
        <div class="container">
          <h1>DGGram UX Case Study</h1>
          <p>Enhancing User Experience for a Digital Grampanchayat Platform</p>
          <img src="@/assets/digital-grampanchayat.png" alt="DGGram Case Study" class="hero-image" />
        </div>
      </section>
  
      <!-- Project Overview Section -->
      <section class="project-overview">
        <div class="container">
          <h2>Project Overview</h2>
          <p>
            DGGram is a digital platform designed to streamline and digitize the
            operations of Grampanchayat offices in rural India. The project aimed to
            create an intuitive system that caters to multiple stakeholders,
            including residents, Grampanchayat officials, and delivery personnel.
          </p>
          <ul class="overview-details">
            <li><strong>Role:</strong> UX Designer and Researcher</li>
            <li><strong>Tools Used:</strong> Figma, Miro, Adobe XD</li>
            <li><strong>Project Duration:</strong> June 2023 - August 2023</li>
          </ul>
        </div>
      </section>
  
      <!-- Research and Analysis Section -->
      <section class="research-section">
        <div class="container">
          <h2>Research and Analysis</h2>
          <p>
            Before starting the design process, I conducted a comprehensive research
            phase to understand the needs and pain points of users in rural areas.
            The research included surveys, interviews, and contextual inquiries.
          </p>
          <img src="@/assets/digital-grampanchayat.png" alt="Research Analysis" class="research-image" />
        </div>
      </section>
  
      <!-- User Flow and Information Architecture -->
      <section class="user-flow-section">
        <div class="container">
          <h2>User Flow and Information Architecture</h2>
          <img src="@/assets/digital-grampanchayat.png" alt="User Flow" class="user-flow-image" />
          <p>
            The user flow and IA were designed to ensure seamless navigation through
            the platform for all stakeholders, reducing confusion and enhancing
            usability.
          </p>
        </div>
      </section>
  
      <!-- Wireframes and Prototypes Section -->
      <section class="wireframes-section">
        <div class="container">
          <h2>Wireframes and Prototypes</h2>
          <img src="@/assets/digital-grampanchayat.png" alt="Wireframes" class="wireframes-image" />
          <p>
            Low-fidelity wireframes were created to visualize the layout and
            structure. These were iterated based on feedback before moving on to
            high-fidelity designs.
          </p>
        </div>
      </section>
  
      <!-- Final Design Section -->
      <section class="final-design-section">
        <div class="container">
          <h2>Final Design and Solution</h2>
          <img src="@/assets/digital-grampanchayat.png" alt="Final Design" class="final-design-image" />
          <p>
            The final design incorporated all feedback and usability testing
            insights, resulting in a cohesive, user-friendly platform that serves
            the diverse needs of its users.
          </p>
        </div>
      </section>
  
      <!-- Footer Section -->
      <footer class="footer-section">
        <div class="container">
          <p>Interested in collaborating? <a href="/contact">Contact Me</a></p>
        </div>
      </footer>
    </div>
  </template>
  
  <script>
  export default {
    name: "DggramPage",
  };
  </script>
  
  <style scoped>
  .case-study-page {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  /* Hero Section */
  .hero-section {
    text-align: center;
    padding: 60px 20px;
    background-color: #f7f7f7;
  }
  
  .hero-section h1 {
    font-size: 2.5em;
  }
  
  .hero-section p {
    font-size: 1.2em;
    color: #555;
  }
  
  .hero-image {
    width: 100%;
    max-width: 800px;
    margin-top: 20px;
  }
  
  /* Project Overview */
  .project-overview {
    padding: 40px 20px;
    background-color: #ffffff;
  }
  
  .project-overview h2 {
    margin-bottom: 20px;
  }
  
  .overview-details {
    list-style-type: none;
    padding: 0;
  }
  
  .overview-details li {
    margin: 5px 0;
  }
  
  /* Research Section */
  .research-section {
    padding: 40px 20px;
    background-color: #f1f1f1;
  }
  
  .research-image {
    width: 100%;
    max-width: 900px;
    margin: 20px auto;
    display: block;
  }
  
  /* User Flow Section */
  .user-flow-section {
    padding: 40px 20px;
    background-color: #ffffff;
  }
  
  .user-flow-image {
    width: 100%;
    max-width: 900px;
    margin: 20px auto;
  }
  
  /* Wireframes Section */
  .wireframes-section {
    padding: 40px 20px;
    background-color: #f1f1f1;
  }
  
  .wireframes-image {
    width: 100%;
    max-width: 900px;
    margin: 20px auto;
  }
  
  /* Final Design Section */
  .final-design-section {
    padding: 40px 20px;
    background-color: #ffffff;
  }
  
  .final-design-image {
    width: 100%;
    max-width: 900px;
    margin: 20px auto;
  }
  
  /* Footer */
  .footer-section {
    padding: 20px 0;
    background-color: #333;
    color: #fff;
    text-align: center;
  }
  </style>