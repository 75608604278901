<template>
    <h2 class="page-header">Research Publications</h2>
    <div class="research-container">
        <!-- Header Section -->
        <section class="page-header">
            
            <p class="intro-paragraph" style="text-align: left;">
                I am an HCI and UX researcher with a strong focus on advancing the fields of<strong> Artificial Intelligence, Machine Learning, Human-Computer Interaction, and Conversational Interfaces</strong>. My research spans several areas, including Speech Recognition, Pattern Recognition, Psychology, Digital Product Design and Computational Optimization. </p>
        </section>
    
        <!-- Research Sections as Cards -->
        <div class="research-sections">
            <!-- Journal Publications -->
            <div class="research-card">
                
                <h3><font-awesome-icon icon="fa-solid fa-book-open fa-1x" style="color: #df7920;" /> Journal Publications</h3>
                <p class="card-description">
                    A collection of my journal publications published in <strong>SCI, Scopus, and UGC approved journals </strong>  highlighting my contributions to Digital Product Design, User Experience Design,Speech Recognition, Pattern Recognition, Big Data Analytics and more.
                </p>
                <button @click="showSection('journals')" class="expand-button">View Journal Publications</button>
                <ul v-show="activeSection === 'journals'" class="publication-list" style="margin-left: 8px;">
                    <li>
                        Ruta Hanchekar, Dr. Ravindra Bachate, Ms. Shivani Sawant, “Digital Solution for Loneliness and Social Isolation in the Middle Age", ShodhKosh: Journal of Visual and Performing Arts,Vol. 5 No. ICETDA24 (2024).
                    </li>
                    <li>
                        Maulik Patel, Dr. Ravindra Bachate, Rahul Satyavada, Satish Shende, “A Comprehensive Review of Design Education for Sustainable Development,” ShodhKosh: Journal of Visual and Performing Arts,Vol. 5 No. ICETDA24 (2024).
                    </li>
                    <li>
                        Manali Purohit, Dr. Ravindra Bachate, “User-Centric Digital Solution for Diabetic Patients,” ShodhKosh: Journal of Visual and Performing Arts,Vol. 5 No. ICETDA24 (2024) .
                    </li>
                    <li>
                        Nirali Makawana, Dr. Ravindra Bachate, “Zenify: A Holistic Approach to Mitigating Attention Deficit Hyperactivity Disorder (ADHD) Symptoms,” ShodhKosh: Journal of Visual and Performing Arts,Vol. 5 No. ICETDA24 (2024).
                    </li>
                    
                    <li>
                        Ravindra Bachate, Ashok Sharma, Amar Singh, “Enhanced Marathi Speech Recognition enabled by Grasshopper Optimisation‑based Recurrent Neural Network”, Computer Systems Science & Engineering, DOI:
                        <a href="https://doi.org/10.32604/csse.2022.024214" target="_blank">10.32604/csse.2022.024214</a>
                    </li>
                    <li>
                        Parallel Big Bang-Big Crunch-LSTM Approach for Developing a Marathi Speech Recognition System. Mobile Information Systems. 2022. 1-11. 10.1155/2022/8708380. 
                    </li>
                    <li>
                        Ravindra Bachate, Ashok Sharma, “Comparing different pattern recognition approaches for Building Marathi ASR System”, International Journal of Advanced Science and Technology, Vol. 29, No. 5, (2020), pp. 4615 – 4623.
                    </li>
                    <li>
                        Ravindra Bachate, Dr. Ashok Sharma, “Automatic Speech Recognition systems for Regional Languages in India” - International Journal of Recent Technology and Engineering (IJRTE).
                    </li>
                    <li>
                        Ravindra Bachate, Supriya Dumbare, Gayatri Ladhe, Snehal Binawade, Srushti Gujar, “Understanding tools and applications of Data Analytics”, International Journal of Advance Research and Innovative Ideas in Education, Vol‑3 Issue‑6 2017.
                    </li>
                    <li>
                        Ishwarappa Kalbandia, Mohan V Pawar, Nikhilkumar, Ravindra Bachate, “IPTV Software Process and Workflow”, 2nd International Symposium on Big Data and Cloud Computing (ISBCCʼ15), Elsevier Publication, Procedia Computer Science 50 (2015), pp. 128 – 134.
                    </li>
                    <li>
                        Ravindra Bachate, Prof. H.A. Hingoliwala, “MRDS Data Processing and Mining using Hadoop in Cloud”, International Journal of Computer Applications (IJCA), (0975 – 8887) Volume 90 – No 10, March 2014.
                    </li>
                    <li>
                        Ravindra Bachate, Prof. H.A. Hingoliwala, “Improving performance of Apriori Algorithm using Hadoop”, International Journal of Research in Engineering and Technology (IJRET), (2319 – 1163) Volume 03 – No 12, December 2014.
                    </li>
                    <li>
                        Ravindra Bachate, Supriya Dumbare, Gayatri Ladhe, Snehal Binawade, Srushti Gujar, “Reducing Number of Parameters for Identifying Breast Cancer”, International Journal Of Advance Scientific Research and Engineering Trends, Volume 3, Issue 6, June 2018.
                    </li>
                    <!-- More journal publications go here -->
                </ul>
            </div>
    
            <!-- Book Chapters -->
            <div class="research-card">
                
                <h3><font-awesome-icon icon="fa-solid fa-book fa-1x" style="color: #df7920;" />  Book Chapters</h3>
                <p class="card-description">
                    Explore the scopus indexd book chapters I've contributed to, featuring topics like HCI and IoT-based solutions.
                </p>
                <button @click="showSection('bookChapters')" class="expand-button">View Book Chapters</button>
                <ul v-show="activeSection === 'bookChapters'" class="publication-list" style="margin-left: 8px;">
                    <li>
                        Bachate, R.P., Sharma, A., Singh, A. (2022). Duo Features with Hybrid-Meta-Heuristic-Deep Belief Network Based Pattern Recognition for Marathi Speech Recognition. In: Gupta, D., Khanna, A., Kansal, V., Fortino, G., Hassanien, A.E. (eds) Proceedings of Second Doctoral Symposium on Computational Intelligence . Advances in Intelligent Systems and Computing, vol 1374. Springer, Singapore.
                        <a href="https://doi.org/10.1007/978-981-16-3346-1_53" target="_blank">https://doi.org/10.1007/978-981-16-3346-1_53</a>
                    </li>
                    <li>
                        Riya Narake, Shruti Wagh, Abhishek Tupe, Ravindra Bachate, "Checkmate: An IoT Integrated Tangible Chessboard," Smart Sensors for Industry 4.0: Fundamentals, Fabrication, and IIoT Applications, Wiley Publication, 2024, pp. 95-107, DOI:
                        <a href="https://doi.org/10.1002/9781394214723.ch7" target="_blank">https://doi.org/10.1002/9781394214723.ch7</a>
                    </li>
                    <li>
                        Tanvi Vaze, Harshal Vavale, Janvi Agarwal, Vaishnavi Telang, Ravindra Bachate, "Farm Shielding: A Shielding Experience That Takes a New Turn," Smart Sensors for Industry 4.0: Fundamentals, Fabrication, and IIoT Applications, Wiley Publication, 2024, pp. 83-93, DOI:
                        <a href="https://doi.org/10.1002/9781394214723.ch6" target="_blank">https://doi.org/10.1002/9781394214723.ch6</a>
                    </li>

                    <!-- More book chapters go here -->
                </ul>
            </div>
    
            <!-- Conference Papers -->
            <div class="research-card">
                
                <h3><font-awesome-icon icon="fa-solid fa-file-alt fa-1x" style="color: #df7920;"/>  Conference Papers</h3>
                <p class="card-description">
                    Conference papers presented at various international conferences, focusing on <strong>emerging research trends in AI, UX Design and HCI</strong>.
                </p>
                <button @click="showSection('conferencePapers')" >View Conference Papers</button>
                <ul v-show="activeSection === 'conferencePapers'" class="publication-list" style="margin-left: 8px;">
                    <li>
                        A. A. Aga, R. R. Kalita, H. Jaagi, Ravindra Bachate, K. Garg, "Manzil: An Interactive Data Visualization Project Exploring Everyday Spaces," 2022 International Conference on Advanced Computing Technologies and Applications (ICACTA), 2022, pp. 1-6, DOI:
                        <a href="https://doi.org/10.1109/ICACTA54488.2022.9753082" target="_blank">10.1109/ICACTA54488.2022.9753082</a>
                    </li>
                    <li>
                        Ravindra Bachate, Ashok Sharma, Amar Singh, "Duo Features with Hybrid-Meta-Heuristic-Deep Belief Network Based Pattern Recognition for Marathi Speech Recognition," Proceedings of Second Doctoral Symposium on Computational Intelligence, Advances in Intelligent Systems and Computing, vol 1374, Springer, Singapore, DOI:
                        <a href="https://doi.org/10.1007/978-981-16-3346-1_53" target="_blank">https://doi.org/10.1007/978-981-16-3346-1_53</a>
                    </li>
                  
                    <li>
                        Ravindra Bachate, Dr. Ashok Sharma, “Acquaintance with Natural Language Processing for Building Smart Society,” E3S Web of Conferences (2019), 6th International Conference "Energy & City of the Future," MITADT University, Pune. <a href="https://www.e3s-conferences.org/articles/e3sconf/pdf/2020/30/e3sconf_evf2020_02006.pdf" target="_blank">PDF</a>
                    </li>
                    <li>
                        Ravindra Bachate, Sayali Gaikwad, Pranali Nale, “Survey on Big Data Analytics for Digital World,” 2016 IEEE International Conference on Advances in Electronics, Communication and Computer Technology (ICAECCT), Rajarshi Shahu College of Engineering, Pune, India, Dec 2-3, 2016.
                    </li>
                    <li>
                        Manali Purohit, Dr. Ravindra Bachate, “User-Centric Digital Solution for Diabetic Patients,” 2nd International Conference on Emerging Trends in Design & Arts (ICETDA 2024).
                    </li>
                    <li>
                        Nirali Makawana, Dr. Ravindra Bachate, “Zenify: A Holistic Approach to Mitigating Attention Deficit Hyperactivity Disorder (ADHD) Symptoms,” 2nd International Conference on Emerging Trends in Design & Arts (ICETDA 2024).
                    </li>
                    <li>
                        Ruta Hanchekar, Dr. Ravindra Bachate, Ms. Shivani Sawant, “Digital Solution for Loneliness and Social Isolation in the Middle Age,” 2nd International Conference on Emerging Trends in Design & Arts (ICETDA 2024).
                    </li>
                    <li>
                        Maulik Patel, Dr. Ravindra Bachate, Rahul Satyavada, Satish Shende, “A Comprehensive Review of Design Education for Sustainable Development,” 2nd International Conference on Emerging Trends in Design & Arts (ICETDA 2024).
                    </li>
                      <li>
                        Attended India HCI 2020, 11th edition of the Indian conference series on Human-Computer Interaction (Design and Research), held virtually on 5th-8th November 2020.
                    </li>
                    
                    <!-- More conference papers go here -->
                </ul>
            </div>
    
            <!-- Copyrights -->
            <div class="research-card">
                
                <h3><font-awesome-icon icon="fa-solid fa-book-open fa-1x" style="color: #df7920;" />  Copyrights</h3>
                <p class="card-description">
                    A list of copyrights registered for my innovative work in <strong> AI and speech recognition systems.</strong>
                </p>
                <button @click="showSection('copyrights')" >View Copyrights</button>
                <ul v-show="activeSection === 'copyrights'" class="publication-list" style="margin-left: 8px;">
                    <li>Title: Enhanced Marathi Speech Recognition Enabled By Grasshopper Optimisation Based Recurrent Neural Network, Registration Number: SW-15009/2021</li>
                    <li>Title: Duo Features With Hybrid Metaheuristics Deep Belief Network Based Marathi Speech Recognition System - Duo Features With Hybrid Metaheuristics Deep Belief Network Based Marathi Speech Recognition System, Registration Number: SW-14703/2021</li>
                    <!-- More copyrights go here -->
                </ul>
            </div>
    
            <!-- Reviewer Roles -->
            <div class="research-card">
                
                <h3><font-awesome-icon icon="fa-solid fa-graduation-cap fa-1x" style="color: #df7920;" />  Reviewer Roles</h3>
                <p class="card-description">
                    Reviewer for various <strong>SCI/Scopus international journals and conferences </strong>in the fields of Design,AI, machine learning, and computational intelligence.
                </p>
                <button @click="showSection('reviewerRoles')" >View Reviewer Roles</button>
                <ul v-show="activeSection === 'reviewerRoles'" class="publication-list" style="margin-left: 8px;">
                    <li>Engineering Applications of Artificial Intelligence (SCI/Scopus Indexed)</li>
                    <li>Computer Communications, Elsevier (SCI/Scopus Indexed)</li>
                    <li>Journal of Intelligent & Fuzzy Systems (SCI/Scopus Indexed)</li>
                    <li>International Conference "MITADTSoCiCON2024" at MIT Art Design & Technology University, School of Computing, Pune, April 25-27, 2024.</li>
                <!-- More reviewer roles go here -->
                </ul>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    export default {
        name: "ResearchPage",
        data() {
            return {
                activeSection: '', // Keep track of the currently open section
            };
        },
        methods: {
            showSection(section) {
                this.activeSection = this.activeSection === section ? '' : section; // Toggle section view
            },
        },
    };
    </script>
    
    <style scoped>
    /* Container styling */
    .research-container {
        padding: 30px;
        background: #f8f8f8;
    }
    
    /* Research Header */
    .research-header {
        text-align: center;
        margin-bottom: 40px;
    }
    
    .intro-paragraph {
       
        line-height: 1.8;
    }
    
    /* Research Sections */
    .research-sections {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    /* Card Styling */
    .research-card {
        background-color: #fff;
        padding: 20px;
        border-radius: 12px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
        width: 100%; /* Take full width of the container */
    }
    
    .research-card:hover {
        transform: translateY(-5px);
    }
    
    /* Card Content */
    .card-icon {
        width: 50px;
        height: auto;
        margin-bottom: 15px;
    }
    
    .card-description {
       
        margin: 15px 0;
    }
    
    
  
    
    /* Publication List */
    .publication-list {
        list-style-type: none;
        padding-left: 0;
        margin-top: 15px;
    }
    
    .publication-list li {
        margin-bottom: 10px;
        text-align: left;
    }
    ul {
  list-style-type: disc !important; /* Display bullet points */
  padding-left: 20px; /* Add padding to create space for the bullets */
}

li {
  margin-bottom: 10px; /* Optional: Add some space between items */
}
    </style>