<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light sticky-navbar" style="color: black !important;height: 64px;overflow: hidden; ">
      <div class="container">
        <!-- Brand Name (Left Side) -->
        <router-link to="/" class="navbar-brand" style="font-weight: bold;">Dr. Ravindra Bachate</router-link>
  
        <!-- Navigation links (Right Side) -->
        <ul class="navbar-nav ml-auto" style="color: black !important;">
          <li class="nav-item">
            <router-link class="nav-link" to="/researchpage">Research</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/mentorshippage">UX Mentorship</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/workpage">Work</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/resumepage">Resume</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/blogpage">Blogs</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/contactpage">Contact</router-link>
          </li>
        </ul>
      </div>
    </nav>
  </template>
  
  <script>
  export default {
    name: 'HeaderPage',
  };
  </script>
  
  <style scoped>
  .nav-link {
    text-decoration: none;
    color: black !important;
    font-size: 1.5rem;
    margin: 3px;
  }
  
  .nav-link:hover {
    color: gray;
  }
  .sticky-navbar {
  position: sticky;
  top: 0; /* Sticks to the top */
  z-index: 1000; /* Ensure it stays above other content */
  width: 100%; /* Ensure full-width navbar */
  }
  .router-link-active {
  color: #ED6E2A!important; /* Change to your desired color */
  font-weight: bold; /* Optional: make the active link bold */
}
  </style>
  