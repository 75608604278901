<template>
  <header class="floating-header">
    <!-- Desktop Header Container -->
    <div class="header-container" v-if="!isMobileView || menuOpen">
      <!-- Navigation Menu for Desktop -->
      <nav class="nav-menu" v-show="!isMobileView || menuOpen">
        <ul>
          <li><router-link to="/" exact @click="closeMenu">Home</router-link></li>
          <li><router-link to="/WorkPage" @click="closeMenu">Work</router-link></li>
          <li><router-link to="/ResearchPage" @click="closeMenu">Research</router-link></li>
          <li><router-link to="/MentorshipPage" @click="closeMenu">Mentorship</router-link></li>
          <li><router-link to="/ResumePage" @click="closeMenu">Resume</router-link></li>
          <li><router-link to="/ContactPage" @click="closeMenu">Contact</router-link></li>
          <li><a href="https://medium.com/@ravindrabachate" target="_blank">Blog</a></li>
        </ul>
      </nav>
    </div>

    <!-- Mobile Bottom Navigation Bar -->
    <nav class="bottom-nav" v-if="isMobileView">
      <ul class="nav-items">
        <li><router-link to="/" exact>Home</router-link></li>
        <li><router-link to="/WorkPage">Work</router-link></li>
        <li><router-link to="/ResearchPage">Research</router-link></li>
        <li v-if="!showMore"><button @click="toggleMoreMenu">More</button></li>
        <li v-if="showMore"><router-link to="/MentorshipPage">Mentorship</router-link></li>
        <li v-if="showMore"><router-link to="/ResumePage">Resume</router-link></li>
        <li v-if="showMore"><router-link to="/ContactPage">Contact</router-link></li>
        <li v-if="showMore"><a href="https://medium.com/@ravindrabachate" target="_blank">Blog</a></li>
        <li v-if="showMore"><button @click="toggleMoreMenu">Less</button></li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'FloatingHeader',
  data() {
    return {
      isScrolled: false,
      menuOpen: false, // Toggle state for menu
      isHeaderVisible: true, // Toggle state for header visibility
      isMobileView: false, // State to track if mobile view is active
      showMore: false, // Toggle state for showing "More" menu items
      lastScrollY: 0, // Track the last scroll position
    };
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 50;

      // Toggle header visibility based on scroll direction
      if (window.scrollY > this.lastScrollY && window.scrollY > 50) {
        this.isHeaderVisible = false; // Scroll down - hide header
      } else {
        this.isHeaderVisible = true; // Scroll up - show header
      }
      this.lastScrollY = window.scrollY;
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    closeMenu() {
      this.menuOpen = false;
    },
    toggleMoreMenu() {
      this.showMore = !this.showMore;
    },
    checkMobileView() {
      this.isMobileView = window.innerWidth <= 768; // Adjust based on your mobile breakpoint
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.checkMobileView);
    this.checkMobileView(); // Initial check for mobile view
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.checkMobileView);
  },
};
</script>
<style scoped>
/* Floating Header Styles */
.floating-header {
  height: 60px;
  position: fixed;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background: rgb(255, 255, 254);
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 0 10px;
}

.hide-header {
  transform: translateX(-50%) translateY(100%);
}

.header-scrolled {
  background: white;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  width: auto;
}

.nav-menu {
  display: flex;
}

.nav-menu ul {
  display: flex;
  list-style: none;
  gap: 20px;
  padding: 0;
  margin: 0;
}

.nav-menu ul li a {
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-menu ul li a:hover,
.nav-menu ul li .router-link-active {
  color: #df7920;
  border-bottom: 1px solid #df7920;
}

.bottom-right-hamburger {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #ffa565;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  z-index: 1001;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-menu-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
}

.nav-menu-mobile.show {
  visibility: visible;
  opacity: 1;
}

.nav-menu-mobile ul {
  list-style: none;
  padding: 0;
  text-align: center;
}

.nav-menu-mobile ul li a {
  color: #fff;
  font-size: 1.5rem;
  padding: 15px 0;
  display: block;
}

/* Bottom Navigation for Mobile */
.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-around;
  padding: 10px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.nav-items {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: space-around;
  width: 100%;
}

.nav-items li {
  flex: 1;
  text-align: center;
}

.nav-items li button {
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 1rem;
}

.nav-items li a {
  color: #333;
  text-decoration: none;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .floating-header {
    display: none;
  }
}
</style>