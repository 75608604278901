<template>
  <!-- Hero Section -->
  <div class="hero-section col-sm-12">
    <img :src="require('@/assets/ravindra.png')" alt="Ravindra" class="hero-image" />
  </div>

  <!-- Section: Thoughts on UX/Interaction Design -->
  <div class="thoughts-section">
    <div class="container thoughts-container">
      <h2>What UX/Interaction Design Means to Me?</h2>
      <p>
        <strong>UX and Human Computer Interaction design</strong> are more than just contemporary trends—they are the result of decades of advancements,
        <strong> from early command-line interfaces to intuitive graphical user interfaces</strong> that revolutionized human-machine interactions. Interaction design is about crafting a harmonious relationship between humans and machines, centering on how people connect with <strong>digital products.</strong> It’s a dynamic discipline where we blend <strong>technology, human behavior, and cognitive psychology</strong> to create seamless, engaging, and intuitive experiences that resonate deeply with users. In essence, Interaction Design encompasses designing for a range of technologies, including
        <strong>Mobile, Web, Smart Watches, POS systems, ATMs, Smart Screens, IoT devices, Conversational UIs, and beyond.</strong>
      </p>
      <div class="col-12 center">
        <img :src="require('@/assets/UX Design Process.png')" alt="UX Design Process" class="img-fluid process-image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
};
</script>

<style scoped>
/* Global Hero Section Styling */
.hero-section {
  width: 100%; /* Full width */
  height: 80vh; /* 80% of viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Hide any overflow */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover entire section */
}

/* Thoughts on UX/Interaction Design Section */
.thoughts-section {
  padding: 50px 20px; /* Uniform padding on all sides */
  background-color: #e2e0d6; /* Light background color */
  display: flex;
  justify-content: center;
}

.thoughts-container {
  max-width: 100%; /* Full width container */
  margin: 0 5vw; /* Same left and right margins as hero section */
}

.thoughts-section h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 2em; /* Responsive font size */
}

.thoughts-section p {
  line-height: 1.6;
  color: #555;
  text-align: justify;
  font-size: 1.25em;
}

/* UX Design Process Image Section */
.process-image {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

/* Responsive Styling */
@media (max-width: 992px) {
  .hero-section {
    height: 60vh; /* Adjust height for medium screens */
  }

  .thoughts-container {
    max-width: 100%; /* Full width on medium screens */
    margin: 0 3vw; /* Adjusted margins for consistency */
  }

  .thoughts-section h2 {
    font-size: 1.75em; /* Smaller font size */
  }
}

@media (max-width: 768px) {
  .hero-section {
    height: auto; /* Auto height for small screens */
    padding: 30px 20px;
  }

  .thoughts-container {
    margin: 0 2vw; /* Adjusted margins for small screens */
  }

  .thoughts-section h2 {
    font-size: 1.5em; /* Smaller font size */
  }

  .thoughts-section p {
    font-size: 0.9em; /* Smaller font size */
  }
}

@media (max-width: 576px) {
  .hero-section {
    height: auto; /* Auto height for extra small screens */
    flex-direction: column; /* Stack elements vertically */
  }

  .hero-image {
    width: 100%;
    height: auto;
  }

  .thoughts-container {
    margin: 0 1vw; /* Smaller margin on very small screens */
  }

  .thoughts-section h2 {
    font-size: 1.2em; /* Further reduced font size */
  }

  .thoughts-section p {
    font-size: 0.85em; /* Smaller font size */
  }

  .process-image {
    width: 90%; /* Adjusted image width */
    margin-top: 15px;
  }
}
</style>