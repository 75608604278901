<template>
  <div class="portfolio-container">
    <!-- Page Header -->
    <h2 class="page-header">UX Case Studies</h2>

    <!-- Case Studies Section -->
    <section class="case-studies-section col-sm-12">
      <!-- Individual Hero Sections for each project -->
      <div class="hero-section project-grocart" style="background-color: rgb(240, 226, 205);">
        <div class="hero-content col-sm-12">
          <!-- Text Section (Left) -->
          <div class="hero-text">
            <h3>Grocart</h3>
            <h4>White-Labeled E-commerce Platform</h4>
            <ul>
              <li>Flexible e-commerce solution enabling easy launch of branded online stores.</li>
              <li>Offers customization and intuitive interfaces for simplified management.</li>
              <li>Delivers a seamless experience for users and admins.</li>
            </ul>
            <button class="view-project" @click="openPasswordModal('Grocart', '/pdfs/grocart-case.pdf', false, true)">
              View Case Study
            </button>
          </div>
          <!-- Image Section (Right) -->
          <div class="hero-image">
            <img src="@/assets/grocart.png" alt="Grocart" class="image-fluid" />
          </div>
        </div>
      </div>

      <!-- Other projects with similar structure -->
      <div class="hero-section project-digital-grampanchayat" style="background-color: rgb(207, 223, 207);">
        <div class="hero-content">
          <div class="hero-text">
            <h3>Digital Grampanchayat</h3>
            <h4>Transforming India through village digitalization.</h4>
            <ul>
              <li>Buying and selling products online</li>
              <li>Online property tax payment</li>
              <li>Access to farm crop market rates</li>
              <li>Information on government schemes</li>
              <li>Digital access to all Grampanchayat services</li>
              <li>Facilitates seamless communication between villagers and local administration</li>
            </ul>
            <button class="view-project" @click="openPasswordModal('Digital Grampanchayat', '/pdfs/digital-grampanchayat-case.pdf', false, true)">
              View Case Study
            </button>
          </div>
          <div class="hero-image">
            <img src="@/assets/digital-grampanchayat.png" alt="Digital Grampanchayat" class="image-fluid" />
          </div>
        </div>
      </div>
      <div class="hero-section project-mobile-recording" style="background-color: #d5e7ea;">
          <div class="hero-content">
            <div class="hero-text">
              <h3>Mobile Recording Application</h3>
              <h4>Redefining audio recording</h4>
              <ul>
                <li>Affordable real-time audio recording and monitoring mobile application.</li>
                <li>Cost-effective solution for high-quality sound recording during filming without professional equipment.</li>
              </ul>
              <p style="color: red;">Details cannot be shared under the NDA agreement.</p>
            </div>
            <div class="hero-image">
              <img src="@/assets/sync4-mockup.png" alt="Mobile Recording" class="image-fluid" />
            </div>
          </div>
        </div>
  
        <div class="hero-section project-khelnow" style="background-color: #d1dfef;">
          <div class="hero-content">
            <div class="hero-text">
              <h3>KhelNow Sports Website Redesign</h3>
              <h4>Enhancing the sports experience through UCD and strategic insights.</h4>
              <ul>
                <li>Conducted UX research to identify usability issues and improvement areas.</li>
                <li>Analyzed the website to discover opportunities for a better user experience.</li>
                <li>Proposed solutions to enhance engagement and usability.</li>
                <li>Developed information architecture to improve content organization.</li>
                <li>Created wireframes to visualize the improved structure and layout.</li>
              </ul>
              <button class="view-project" @click="openPasswordModal('KhelNow', '/pdfs/khelnow-case.pdf', false, true)">
                View Case Study
              </button>
            </div>
            <div class="hero-image">
              <img src="@/assets/khelnow.png" alt="KhelNow" class="image-fluid" />
            </div>
          </div>
        </div>
  
        <div class="hero-section project-apartment" style="background-color: #e6d5e5;">
          <div class="hero-content">
            <div class="hero-text">
              <h3>Apna Apartment</h3>
              <h4>Simplifying community management with an integrated digital solution.</h4>
              <ul>
                <li>Integrated platform for residents, admins, and watchmen.</li>
                <li>User app: book amenities, lodge complaints, get notifications.</li>
                <li>Admin app: manage residents, bookings, and communication.</li>
                <li>Watchman app: track visitors and manage security.</li>
                <li>Enhances communication and efficiency in communities.</li>
              </ul>
              <button class="view-project" @click="openPasswordModal('Apna Apartment', '/pdfs/apartment-case.pdf', false, true)">
                View Case Study
              </button>
            </div>
            <div class="hero-image">
              <img src="@/assets/apartment.png" alt="Apna Apartment" class="image-fluid" />
            </div>
          </div>
        </div>
      <!-- Add more case studies here -->
    </section>

    <!-- Password Modal -->
    <transition name="fade">
      <div v-if="isPasswordModalVisible" class="password-modal" style="background: none !important;">
        <div class="modal-content">
          <h3>Password Required</h3>
          <p>Please enter the password to access this case study.</p>
          <!-- Centered Input Field -->
          <div class="input-container">
            <input type="password" v-model="inputPassword" placeholder="Enter Password" class="password-input"/>
          </div>
          <div class="modal-buttons">
            <button class="close-btn" @click="closePasswordModal">Close</button>
            <button class="submit-btn" @click="checkPassword">Submit</button>
          </div>
        </div>
      </div>
      
    </transition>

    <!-- Bottom Sheet Modal -->
    <BottomSheetModal
      :isVisible="isModalVisible"
      :title="modalTitle"
      :content="modalContent"
      :isImage="isContentImage"
      :isPdf="isContentPdf"
      @close="closeModal"
    />
  </div>
</template>

<script>
import BottomSheetModal from "@/components/BottomSheetModal.vue";

export default {
  name: "WorkPage",
  components: {
    BottomSheetModal,
  },
  data() {
    return {
      isPasswordModalVisible: false, // Password modal visibility state
      isModalVisible: false, // BottomSheet modal visibility state
      inputPassword: "", // Model for input password
      modalTitle: "", // Title for the modal
      modalContent: "", // Content for the modal
      isContentImage: false, // Is content an image?
      isContentPdf: false, // Is content a PDF?
    };
  },
  methods: {
    openPasswordModal(title, content, isImage, isPdf) {
      this.modalTitle = title;
      this.modalContent = content;
      this.isContentImage = isImage;
      this.isContentPdf = isPdf;
      this.isPasswordModalVisible = true;
    },
    closePasswordModal() {
      this.isPasswordModalVisible = false;
      this.inputPassword = ""; // Reset input field
    },
    checkPassword() {
      const correctPassword = "Rbwork@ux";
      if (this.inputPassword === correctPassword) {
        this.closePasswordModal(); // Close the password modal
        this.isModalVisible = true; // Open the BottomSheet modal
      } else {
        alert("Incorrect password. Please try again.");
      }
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
};
</script>

<style scoped>
/* Container */
.portfolio-container {
  margin: 0 auto;
  padding: 1em;
  max-width: 92%;
}

/* Case Studies Section */
.case-studies-section {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5em;
  justify-content: center;
}

/* Hero Sections */
.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5em 1.5em;
  border-radius: 0.5em;
  margin-bottom: 1em;
  width: 100%;
  transition: transform 0.3s ease;
}

/* Flex Container for Content */
.hero-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 92%;
  flex-direction: row;
  gap: 1.5em;
}

/* Text Section Styling */
.hero-text {
  flex: 1;
}

.hero-text h3 {
  margin-bottom: 0.5em;
  font-size: 1.5em;
}

.hero-text h4 {
  margin-bottom: 1em;
  font-size: 1.2em;
}

/* Image Styling */
.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.hero-image img {
  width: 100%;
  max-width: 30em;
  height: auto;
  border-radius: 0.5em;
}

/* View Project Button */
.view-project {
  padding: 0.75em 1.5em;
  background-color: #df7920;
  color: white;
  border: none;
  border-radius: 0.5em;
  cursor: pointer;
  margin-top: 1em;
}

.view-project:hover {
  background-color: #b85d16;
}

/* Password Modal Styling */
.password-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the modal */
  background-color: none;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  text-align: center;
  width: 100%;
}


/* Center the Input Container */
.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

/* Input Styling */
.password-input {
  width: 60%;
  padding: 10px;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center; /* Center text inside input */
}

/* Buttons */
.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 1em;
}

.close-btn,
.submit-btn {
  background: #df7920;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 8px;
}

.close-btn:hover,
.submit-btn:hover {
  background: #b85d16;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .case-studies-section {
    flex-direction: column;
  }

  .hero-content {
    flex-direction: column;
    gap: 1em;
    width: 100%;
  }

  .hero-text {
    text-align: center;
    width: 100%;
  }

  .hero-image {
    width: 100%;
  }

  .password-modal {
    width: 90%; /* Full width for smaller screens */
    max-width: 400px;
    padding: 15px;
  }
}
</style>