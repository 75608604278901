<template>
  <section><h2 class="page-header" style="position: sticky;">Tech & Design Insights and Musings</h2></section>
  
    <div class="blog-page">
      <!-- Sidebar for Topics -->
      <aside class="sidebar">
        <h3>Topics</h3>
        <ul class="topics-list">
          <li v-for="(topic, index) in topics" :key="index">
            <a href="#" class="topic-link">{{ topic }}</a>
          </li>
        </ul>
        <a href="#" class="see-all-link">See all topics</a>
      </aside>
  
      <!-- Main Content Area -->
      <main class="content-area">
        <!-- Tabs for Navigation -->
        <div class="tabs">
          <button v-for="(tab, index) in tabs" :key="index" class="tab-button" :class="{ active: currentTab === index }" @click="currentTab = index">
            {{ tab }}
          </button>
        </div>
  
        <!-- Blog Articles List -->
        <section class="articles-section">
          <article class="article" v-for="(article, index) in filteredArticles" :key="index">
            <h2 class="article-title">{{ article.title }}</h2>
            <p class="article-meta">{{ article.date }} | {{ article.type }}</p>
            <p class="article-description">{{ article.description }}</p>
            <div v-if="article.video" class="article-video">
              <img :src="article.videoThumbnail" alt="Video Thumbnail" class="video-thumbnail" />
            </div>
          </article>
        </section>
      </main>
    </div>
  </template>
  
  <script>
  export default {
    name: "BlogPage",
    data() {
      return {
        // Sidebar topics
        topics: [
          "Agile",
          "Artificial Intelligence",
          "Design Process",
          "Ecommerce",
          "Intranets",
          "Navigation",
          "Psychology and UX",
          "Research Methods",
          "Study Guides",
          "User Testing",
          "Web Usability",
          "Writing for the Web"
        ],
        // Tabs for article sorting
        tabs: ["Most Recent", "Most Popular", "Videos Only", "Articles Only"],
        currentTab: 0, // Tracks active tab
  
        // Blog articles data
        articles: [
          {
            title: "7 Tips for Successful Discoveries",
            date: "September 27, 2024",
            type: "Article",
            description: "Discovery is challenging; it can be hard to know what to research, how to do discovery as a team, and how to get buy-in. Follow these 7 tips for smoother discovery efforts.",
            video: false
          },
          {
            title: "Accelerating Research with AI",
            date: "September 27, 2024",
            type: "Article",
            description: "AI can speed up certain research tasks but is currently most helpful in the planning and analysis stages.",
            video: false
          },
          {
            title: "Roadmaps vs. Project Timelines",
            date: "September 25, 2024",
            type: "Video",
            description: "Learn how roadmaps can guide product team strategy and project timelines guide execution.",
            video: true,
            videoThumbnail: require('@/assets/RB.png')
          },
          {
            title: "Error Messages: 4 Guidelines for Effective Communication",
            date: "September 23, 2024",
            type: "Video",
            description: "Communicate effectively with your error messages by using human-readable language, concisely and precisely describing the issue, offering constructive advice, and not blaming the user.",
            video: true,
            videoThumbnail: require('@/assets/RB.png')
          }
        ]
      };
    },
    computed: {
      filteredArticles() {
        // Filter articles based on the current tab
        if (this.currentTab === 2) return this.articles.filter(article => article.video);
        if (this.currentTab === 3) return this.articles.filter(article => !article.video);
        return this.articles;
      }
    }
  };
  </script>
  
  <style scoped>
  .blog-page {
    display: flex;
  }
  
  /* Sidebar Styling */
  .sidebar {
    width: 20%;
    padding: 20px;
    background-color: #f8f9fa;
    border-right: 1px solid #ddd;
  }
  
  .sidebar h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .topics-list {
    list-style: none;
    padding: 0;
  }
  
  .topic-link {
    display: block;
    padding: 8px 0;
    color: #333;
    text-decoration: none;
  }
  
  .topic-link:hover {
    color: #007bff;
  }
  
  .see-all-link {
    display: block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
  }
  
  .see-all-link:hover {
    text-decoration: underline;
  }
  
  /* Main Content Area Styling */
  .content-area {
    width: 80%;
    padding: 20px;
  }
  
  .tabs {
    display: flex;
    margin-bottom: 20px;
  }
  
  .tab-button {
    margin-right: 10px;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    background-color: #f8f9fa;
    border-bottom: 3px solid transparent;
  }
  
  .tab-button.active {
    border-bottom: 3px solid #007bff;
    color: #007bff;
  }
  
  .articles-section {
    margin-top: 20px;
  }
  
  .article {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  
  .article-title {
    font-size: 20px;
    margin: 0;
    color: #333;
  }
  
  .article-meta {
    color: #777;
    margin: 5px 0;
  }
  
  .article-description {
    margin: 10px 0;
  }
  
  .article-video {
    display: flex;
    align-items: center;
  }
  
  .video-thumbnail {
    width: 150px;
    margin-left: 20px;
  }
  </style>