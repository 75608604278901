<template>
  <h2 class="page-header">Resume</h2>
  

  <div class="resume-container">
    <!-- Display the uploaded resume image -->
    <img :src="require('@/assets/RBResume.png')" alt="Dr. Ravindra Bachate Resume" class="resume-image" />
  </div>
</template>

<script>
export default {
  name: "ResumePage",
};
</script>

<style scoped>
/* Container Styling */
.resume-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5; /* Light background to contrast the image */
}

/* Image Styling */
.resume-image {
  max-width: 100%;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
</style>