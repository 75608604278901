<template>
  <div class="mobile-redirect-container">
    <img src="@/assets/mobile.png" alt="Mobile View" class="redirect-image" />
    <p class="redirect-text">
      For an enhanced experience, we recommend exploring this site on your Laptop or Desktop!
    </p>
  </div>
</template>

<script>
export default {
  name: 'MobileRedirect',
};
</script>

<style scoped>
/* Global Reset Styles */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Reset body and html to remove unwanted spacing */
html, body {
  margin: 0;
  padding: 0;
  width: 100vw; /* Set full width of viewport */
  height: 100vh; /* Set full height of viewport */
  overflow: hidden; /* Prevent scrollbars */
}

/* Fullscreen container */
.mobile-redirect-container {
  position: absolute; /* Position absolutely within the viewport */
  top: 0;
  left: 0;
  height: 100vh; /* Full height of the screen */
  width: 100vw; /* Full width of the screen */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f5f5f5; /* Light background color */
}

/* Style for the image */
.redirect-image {
  max-width: 140px;
  max-height: 260px;
  margin-bottom: 20px; /* Space between image and text */
}

/* Style for the paragraph text */
.redirect-text {
  font-size: 1.75em; /* Adjusted font size for better readability */
  color: black;
  max-width: 600px; /* Limit text width for readability */
  margin: 0 auto; /* Center the text */
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .redirect-text {
    font-size: 1.5em;
    margin-left: 1em;
    margin-right: 1em;
  }

  .redirect-image {
    max-width: 120px; /* Smaller image size on mobile */
    max-height: 200px;
  }
}
</style>