<template>
    <div id="app">
      <!-- If the user is on mobile, show MobileRedirect component -->
      <MobileRedirect v-if="isMobileView" />
  
      <!-- Show main content only when not on mobile view -->
      <div v-else>
        <FloatingHeader />
        <router-view />
      </div>
    </div>
  </template>
  
  <script>
  // Import your components
  import FloatingHeader from './components/FloatingHeader.vue';
  import MobileRedirect from './components/MobileRedirect.vue'; // Import MobileRedirect component
  
  export default {
    name: 'App',
    components: {
      FloatingHeader,
      MobileRedirect,
    },
    data() {
      return {
        isMobileView: false, // Track if the user is on mobile view
      };
    },
    methods: {
      // Method to check if the user is on a mobile device
      checkMobileView() {
        this.isMobileView = window.innerWidth <= 768; // Set breakpoint for mobile devices
      },
    },
    mounted() {
      this.checkMobileView(); // Check for mobile view on component mount
      window.addEventListener('resize', this.checkMobileView); // Listen for window resize events
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.checkMobileView); // Clean up event listener
    },
  };
  </script>
  
  <style src="./assets/style.css"></style>