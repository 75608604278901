<template>
<h2 class="page-header">UX Mentorship</h2>
<div class="mentoring-container">
    <!-- Mentoring Header Section -->

    <!-- Mentored Projects Section -->
    <section class="mentored-projects">
        <div class="project-card" v-for="project in projects" :key="project.id">
            <div class="card-content">
                <img :src="project.image" alt="Project Image" class="project-image" />
                <h3>{{ project.title }}</h3>
                <p><strong>{{ project.category }}</strong></p>
                <p>{{ project.description }}</p>
                <button class="view-case-study" @click="openModal(project.title, project.content, project.isImage, project.isPdf)">View Case Study</button>
            </div>

        </div>
    </section>

    <!-- Thesis Guided Section -->

    <section class="thesis-section">

        <div class="thesis-card">
            
            <h3><font-awesome-icon icon="fa-solid fa-graduation-cap fa-1x" style="color: #df7920;" />  Masters / Bachelors Graduation Project Thesis Guided</h3>
            <p class="card-description" style="text-align: left;">
                I have mentored many masters / bachelor students' graduation projects who were doing their graduation internship in various multinational companies.Below is the list of students I have mentored.
            </p>

            <ul style="list-style-type: disc; text-align: left;">
                
                <li>Arka Ghosh,"Buy before experience for SmartThings devices",Samsung Research Institute, Bangalore (SRIB),India</li><li>Yash Keni,"Designing experiences for user assistive product information",eQ Technologic,India</li>
                <li>Yashraj Wadalkar, "Understanding MSME in Indian Context for Microsoft Office", Microsoft, India</li>
                <li>Nidhi Palsodkar,"Redesign the user experience model for the "Revenue Optimization" domain",SAS R&D (India).</li>
                <li>Abel Aby John,"FamLink",Thought-Craft,Pune,India</li>
                <li>Rashi Agrawal,"Venue page enhacement",BookMyShow.Mumbai,India</li>
                <li>Riddhi Dedhia, "Next Generation Buying Experience for B2B", SAP Ariba</li>
                <li>Bhargavi Pande,"Designly",Sync Labs - UX Studio,India</li>
                
                <li>Apoorvaa Kashikar,"ARC (Automotive Retail Cloud) Profile Framework",Tekion.corp, Bangalore,India</li>
                <li>Siddhi Thite,"FlyNow Autonomy: Minimizing Human Intervention in Drone Fleet Operations",FlytBase,Pune,India</li>
                <li>Siddhant Rajvanshi, "Designing Life Insurance Platform",Digit Insurance ,Banglore,India</li>
                
                <li>Chirag Kothawade,"Version control: Maintaining single source of Truth for designers",Cubyts Technologies Pvt Ltd.,India</li>
                
                
                <li>Tanya Bansal, "Returns Management System", Grey Orange</li>
                <li>Bhavi Ajmera, “National Digital Health Mission”, Qodequay Technology Pvt. Ltd.</li>
                <li>Isha Vithalani,"Samsung BNPL" Samsung Research Institute, India</li>
                <li>Neelakshi Kar, “Redesigning Requirements Management Tool”, Siemens India</li>
                <li>Mrunal Mule, “User Onboarding for a Saas Startup”, Brane Enterprises Pvt Ltd.</li>
                <li>Nishant Bagmar, “Building Editage Insights 2.0”, Cactus Communication, India</li>

                <li>Pradnya Dhavalikar, "DSA Dashboard Redesign", Dell Technologies,India</li>
                <li>Vedavati Kaulgud, "R Discovery: Creating Explicit / Implicit Personalisation Feed", Cactus Communications,Mumbai,India</li>
                <li>Aasta Mawlikar, "MYBYK ‑ Redesign of a mobile application for commuting", Aubergine Solutions Pvt ltd,India</li>
                <li>Shivangi Vashishth, "Intelligent Interactions For Digital Twins", Siemens Technology And Private Limited, Bengaluru</li>
                <li>Sanika Sharad More, "Improving daily project management experience and Face Recognition System for a remote user identification", Alorb Technologies Private Limited,India</li>

                <li>Amulya Chawda, "NVIDIA User Dashboard Redesign", Nvidia Graphics,India</li>
                <li>Sakshi Lunawat, "Mahindra Finance Dealership Application", Lemon Yellow, India</li>
                <li>Pandkar Kalyani, "AR Jewellery Shopping Application", Divami Design Labs, Hyderabad,India</li>
                <li>Ghatode Yagyesh, "Enhancing experience with gamification at a Brewery", Extentia Information Technology,India</li>
                <li>Badwaik Toshi, "Redesigning of Sehat Sathi Application", Medcord</li>
            </ul>
        </div>
    </section>

    <!-- Bottom Sheet Modal -->
    <BottomSheetModal :isVisible="isModalVisible" :title="modalTitle" :content="modalContent" :isImage="isContentImage" :isPdf="isContentPdf" @close="closeModal" />
</div>
</template>

<script>
import BottomSheetModal from "@/components/BottomSheetModal.vue";

export default {
    name: "MentorshipPage",
    components: {
        BottomSheetModal,
    },
    data() {
        return {
            isModalVisible: false,
            modalTitle: "",
            modalContent: "",
            isContentImage: false,
            isContentPdf: false,
            projects: [{
                    id: 1,
                    title: "Dysgraphia",
                    category: "Design for Special Needs",
                    description: "This project deals with the areas in which dysgraphic students need help are improvement in hand-eye coordination, understanding of time, judgment of distance and space, and understanding of sequences.",
                    image: require("@/assets/dysgraphia.png"),
                    content: "/pdfs/dysgraphia.pdf", // Image content path
                    isImage: false,
                    isPdf: true,
                },
                {
                    id: 2,
                    title: "Art Galleries - Futuristic Design",
                    category: "Design for Future",
                    description: "Wave after wave, new and innovative technologies are being integrated into art galleries. This project investigates what futuristic art galleries would look like in 2050.",
                    image: require("@/assets/artgallery.png"),
                    content: "/pdfs/artgallery.pdf",
                    isImage: false,
                    isPdf: true,
                },
                {
                    id: 3,
                    title: "SARAGAM - Music Visualization",
                    category: "Tangible Interaction Design",
                    description: "To design a solution using Arduino that helps young minds learn classical music through a hands-on experience while explaining the foundation of Hindustani classical music.",
                    image: require("@/assets/sargam.png"),
                    content: "/pdfs/sargam.pdf",
                    isImage: false,
                    isPdf: true,
                },
                {
                    id: 4,
                    title: "Yatra Website Redesign",
                    category: "Usability Testing",
                    description: "A usability testing project for the Yatra website, which focused on enhancing the interface and user flow based on insights received during the testing phase.",
                    image: require("@/assets/yatra.png"),
                    content: "/pdfs/yatra.pdf", // PDF content path
                    isImage: false,
                    isPdf: true,
                },
                {
                    id: 5,
                    title: "An Interactive Board Game for Dyscalculia",
                    category: "Design for Special Needs",
                    description: "This project focused on creating an interactive board game for children with dyscalculia to help improve their numeracy and understanding of basic math operations.",
                    image: require("@/assets/joinit.png"),
                    content: "/pdfs/joinit.pdf",
                    isImage: false,
                    isPdf: true,
                },
                {
                    id: 6,
                    title: "Connect - Strenghening infrastructure for Dairy",
                    category: "Smart India Hackathon 2022",
                    description: "A web-based portal is needed to streamline data for dairy development schemes aimed at enhancing milk production, processing, and marketing infrastructure across the cooperative and private dairy sectors in India.",
                    image: require("@/assets/connect.png"),
                    content: "/pdfs/connect.pdf",
                    isImage: false,
                    isPdf: true,
                },
                {
                    id: 7,
                    title: "Connecting Generations",
                    category: "Technology Trends in Design",
                    description: "Creating a UX case study exclusively using AI tools. The purpose of this assignment is to explore the advantages and limitations of Artificial Intelligence.",
                    image: require("@/assets/AIDesign.png"),
                    content: "/pdfs/AIDesign.pdf",
                    isImage: false,
                    isPdf: true,
                },
                {
                    id: 8,
                    title: "Designing POS for Canteen",
                    category: "Design Method",
                    description: "This case study is about designing Point of Sale software for canteen.In this course, we inestigated the existing billing approach, understood the pain pints and suggested a solution.",
                    image: require("@/assets/pos.png"),
                    content: "/pdfs/pos.pdf",
                    isImage: false,
                    isPdf: true,
                },
            ],
            
        };
    },
    methods: {
        openModal(title, content, isImage, isPdf) {
            this.modalTitle = title;
            this.modalContent = content;
            this.isContentImage = isImage;
            this.isContentPdf = isPdf;
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
        showSection(section) {
            this.activeSection = this.activeSection === section ? "" : section; // Toggle section view
        },
    },
};
</script>

<style scoped>
.mentoring-container {

    margin: 0 auto;
    padding: 10px;

}

/* Header Section */
.mentoring-header {
    text-align: center;

}

.mentoring-header h1 {

    margin-bottom: 8px;
}

.mentoring-header p {

    line-height: 1.6;
}

/* Cards Section */
.mentored-projects {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.project-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s;
}

.project-card:hover {
    transform: translateY(-5px);
}

.card-content {
    padding: 20px;
}

.card-content h3 {

    margin-bottom: 10px;
    color: #df7920;
}

.card-content p {

    margin-bottom: 10px;
    color: #666;
}

.view-case-study {
    margin-top: 15px;
    padding: 10px 15px;

    background-color: #df7920;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.project-image {
    width: 100%;
    /* Sets the image to take the full width of its container */
    height: 200px;
    /* Automatically adjusts height to maintain aspect ratio */
    object-fit: cover;
    /* Ensures the image covers the container without distortion */
    display: block;
    /* Ensures there's no extra space around the image */

}

/* Thesis Section */
.thesis-section {
    margin-top: 36px;
}

.thesis-section h2 {

    margin-bottom: 20px;
    color: #df7920;
}

.academic-year {
    margin-bottom: 30px;
}

.academic-year h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.academic-year ul {
    list-style-type: none;
    padding-left: 0;
}

.academic-year li {

    margin-bottom: 8px;
}

/* Footer Section */
.footer-section {
    margin-top: 50px;
    padding: 20px;
    background-color: #333;
    color: white;
    text-align: center;
}

.footer-nav {
    display: flex;
    justify-content: center;
    list-style: none;
    margin-top: 10px;
}

.footer-nav li {
    margin: 0 15px;
    color: #fff;
}

.thesis-section {
    width: 100%;
    padding: 30px 0;
    background-color: #f9f9f9;
    text-align: center;
}

/* Thesis Cards Container */
.thesis-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 100%;
}

/* Thesis Card Styling */
.thesis-card {
    width: 100vw;
    max-width: 1200px;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px;
    transition: transform 0.3s ease;
}

.thesis-card:hover {
    transform: translateY(-5px);
}

/* Content within Thesis Card */
.card-content {
    text-align: left;
    padding: 20px;
}

/* Thesis List */
.thesis-list {
    list-style-type: disc;
    padding-left: 20px;
}

.thesis-list li {
    font-size: 1.1rem;
    margin-bottom: 10px;
}

/* Responsive Styling */
@media (max-width: 768px) {
    .thesis-card {
        max-width: 100%;
        margin: 0 10px;
        /* Adjust margin for mobile */
    }

    .thesis-list li {
        font-size: 1rem;
    }

    .research-card {
        background-color: #fff;
        padding: 20px;
        border-radius: 12px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
        width: 100%;
        /* Take full width of the container */
    }
}
</style>
