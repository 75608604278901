<template>
  <transition name="slide-up">
    <div v-if="isVisible" class="modal-container">
      <div class="modal-header">
        <!-- Left-aligned Title -->
        <h3 class="modal-title">{{ title }}</h3>
        <!-- Right-aligned Close Button -->
        <button class="close-btn" @click="closeModal">
          <span class="close-text">Close <i class="fas fa-times close-icon"></i></span>
        </button>
      </div>
      <div class="modal-content">
        <!-- Display Image or PDF Content -->
        <img v-if="isImage" :src="content" class="content-image" alt="Case Study Image" />
        <!-- PDF Viewer with script injection to disable right-click -->
        <iframe 
          v-else-if="isPdf" 
          :src="pdfViewerUrl" 
          width="100%" 
          height="100%" 
          class="pdf-viewer" 
          @load="disableRightClickInIframe"
        ></iframe>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "BottomSheetModal",
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    isImage: {
      type: Boolean,
      required: true,
    },
    isPdf: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    pdfViewerUrl() {
      // Modify the URL to hide PDF toolbar controls
      return `${this.content}#toolbar=0&navpanes=0&scrollbar=0`;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    disableRightClickInIframe(event) {
      const iframe = event.target;

      // Ensure the iframe is fully loaded before attempting to access its content
      iframe.contentWindow.addEventListener("contextmenu", (e) => {
        e.preventDefault();
      });
    },
  },
};
</script>

<style scoped>
/* Container for the Modal */
.modal-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(0);
  width: 84vw;
  height: 88vh;
  background: #ffffff;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  overflow-y: auto;
  padding: 0;
  border: 2px gray;
}

/* Modal Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: rgb(234, 233, 233);
  font-size: 14px;
  height: 50px;
  border: 2px grey;
}

/* Left-aligned Modal Title */
.modal-title {
  margin: 0;
  flex-grow: 1;
  text-align: left;
}

/* Close Button */
.close-btn {
  border: 2px solid black;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: color 0.3s;
}

.close-btn:hover {
  color: #ff9a47 !important;
}

/* Close Button Text */
.close-text {
  color: white;
}

/* Modal Content */
.modal-content {
  padding: 20px;
  height: calc(88vh - 50px);
}

/* Content Image */
.content-image {
  max-width: 100%;
  height: auto;
}

/* PDF Viewer Styling */
.pdf-viewer {
  width: 100%;
  height: 100%;
  border: none; /* Remove iframe border */
}

/* Animation */
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>