<template>
    <div class="apartment-case-study">
      <!-- Title and Description Section -->
      <div class="title-section">
        <h1>Apartment Management System</h1>
        <p>
          The apartment management system is an integrated solution designed to streamline communication, management, and security in residential complexes. The system provides separate applications for residents, administrators, and security personnel.
        </p>
      </div>
  
      <!-- Resident Mobile Application Card -->
      <div class="case-study-card resident-card">
        <div class="card-content">
          <h2>Resident Mobile Application</h2>
          <p>
            The Resident App allows residents to manage their profiles, make service requests, track maintenance, and interact with other community members. It features a clean and user-friendly interface with options for managing personal documents, maintenance requests, and payments.
          </p>
        </div>
        <div class="card-image">
          <img src="@/assets/resident-app.png" alt="Resident App" />
        </div>
      </div>
  
      <!-- Admin Dashboard Card -->
      <div class="case-study-card admin-card">
        <div class="card-content">
          <h2>Admin Dashboard</h2>
          <p>
            The Admin Dashboard consolidates all management functions into a single platform. It allows administrators to monitor services, manage resident requests, track maintenance, and facilitate community events.
          </p>
        </div>
        <div class="card-image">
          <img src="@/assets/admin-dashboard.png" alt="Admin Dashboard" />
        </div>
      </div>
  
      <!-- Security Application Card -->
      <div class="case-study-card security-card">
        <div class="card-content">
          <h2>Security Application</h2>
          <p>
            The Security App helps in gate management and visitor tracking, ensuring enhanced security within the residential complex. The app allows security personnel to log visitor entries and exits efficiently and communicate with residents.
          </p>
        </div>
        <div class="card-image">
          <img src="@/assets/security-app.png" alt="Security App" />
        </div>
      </div>
  
      <!-- Back to Work Page Button -->
      <router-link to="/WorkPage">
        <button class="back-to-work">Back to Work Page</button>
      </router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: "ApartmentPage",
  };
  </script>
  
  <style scoped>
  /* Container */
  .apartment-case-study {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
  
  /* Title Section */
  .title-section {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .title-section h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .title-section p {
    max-width: 800px;
    margin: 0 auto;
    font-size: 1.2rem;
    line-height: 1.6;
  }
  
  /* Full-Width Case Study Cards */
  .case-study-card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 20px;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 30px;
  }
  
  .case-study-card:nth-child(odd) {
    flex-direction: row-reverse; /* Alternate direction for odd cards */
  }
  
  .card-content {
    flex: 1;
    padding: 40px;
  }
  
  .card-content h2 {
    margin-bottom: 20px;
  }
  
  .card-content p {
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  /* Images in the card */
  .card-image {
    flex: 1;
    padding: 40px;
  }
  
  .card-image img {
    width: 100%;
    max-width: 600px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Individual Card Background Colors */
  .resident-card {
    background-color: #f0f8ff; /* Light Blue */
  }
  
  .admin-card {
    background-color: #e9f7ef; /* Light Green */
  }
  
  .security-card {
    background-color: #fdf2e9; /* Light Orange */
  }
  
  /* Back to Work Button */
  .back-to-work {
    padding: 12px 24px;
    background-color: #ff6347;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .back-to-work:hover {
    background-color: #ff4500;
  }
  </style>