<template>
    <h2 class="page-header">Get in touch </h2>
  <div class="contact-container">
    <!-- Header Section -->
    <section class="contact-header">
    
      <p>Feel free to reach out to me for <strong>UX Consultancy, Software Development, Portfolio Review and UX Training </strong> through the form below or via email and phone. I look forward to connecting!</p>
    </section>

    <!-- Contact Form Section -->
    <section class="contact-form-section">
      <div class="form-container">
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label for="name">Your Name</label>
            <input type="text" id="name" v-model="name" required placeholder="Enter your name" />
          </div>

          <div class="form-group">
            <label for="email">Your Email</label>
            <input type="email" id="email" v-model="email" required placeholder="Enter your email" />
          </div>

          <div class="form-group">
            <label for="message">Your Message</label>
            <textarea id="message" v-model="message" required placeholder="Write your message"></textarea>
          </div>

          <button type="submit" class="submit-button">Send Message</button>
        </form>
      </div>

      <!-- Contact Info -->
      <div class="contact-info">
        <h3>Contact Information</h3>
        <p><strong>Email:</strong> bachateravi@gmail.com</p>
        <p><strong>Phone:</strong> +91-7588697656</p>

        <!-- Social Media Links -->
        <div class="social-media">
          <h3>Follow Me</h3>
          <a href="https://www.linkedin.com/in/ravindra-bachate-ph-d-715388179/" target="_blank" class="social-icon">
            <i class="fab fa-linkedin fa-2x" style="height: 36px;width: 36px;"></i>
          </a>
          <a href="https://www.instagram.com/ravindrabachate" target="_blank" class="social-icon">
            <i class="fab fa-instagram fa-2x" style="height: 36px;width: 36px;"></i>
          </a>
          <a href="https://medium.com/@ravindrabachate" target="_blank" class="social-icon">
            <i class="fab fa-medium fa-2x" style="height: 36px;width: 36px;"></i>
          </a>
          
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ContactPage',
  data() {
    return {
      name: '',
      email: '',
      message: ''
    };
  },
  methods: {
    submitForm() {
      // Handle form submission logic here
      alert(`Thank you, ${this.name}. Your message has been sent!`);
      this.name = '';
      this.email = '';
      this.message = '';
    }
  }
};
</script>

<style scoped>
.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
}

.contact-header {
  text-align: center;
  margin-bottom: 40px;
}

.contact-header h1 {

  margin-bottom: 10px;
}

.contact-header p {
 
  line-height: 1.6;
}

/* Contact Form */
.contact-form-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form-container {
  flex: 1;
  max-width: 600px;
  margin-right: 20px;
}

.form-group {
  margin-bottom: 4px;
}

.form-group label {

  color: #555;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
 
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea {
  min-height: 150px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #DF7926;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
 
}

.submit-button:hover {
  background-color: #cc6109;
}

/* Contact Info */
.contact-info {
  flex: 1;
  max-width: 400px;
}

.contact-info h3 {
 
  margin-bottom: 10px;
}

.contact-info p {

  margin-bottom: 10px;
  color: #555;
}

/* Social Media Links */
.social-media {
  margin-top: 10px;
}

.social-media h3 {

  margin-bottom: 10px;
}

.social-icon {
  
  margin-right: 4px;
  color: #555;
  text-decoration: none;
 
}

.social-icon:hover {
  color: #ff6347;
}
</style>
