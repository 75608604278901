import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';
import WorkPage from '../components/WorkPage.vue';
import ResearchPage from '../components/ResearchPage.vue';
import MentorshipPage from '../components/MentorshipPage.vue';
import ResumePage from '../components/ResumePage.vue';
import ContactPage from '../components/ContactPage.vue';
import BlogPage from '../components/BlogPage.vue';
import DggramPage from '../components/DggramPage.vue';
import ApartmentPage from '../components/ApartmentPage.vue';
import BottomSheetModal from '../components/BottomSheetModal.vue';
import HeaderPage from '../components/HeaderPage.vue';
import FloatingHeader from '../components/FloatingHeader.vue';
import FooterPage from '../components/FooterPage.vue';
import MobileRedirect from '../components/MobileRedirect.vue';

const routes = [
  { path: '/', component: HomePage, meta: { title: 'Home - Ravindra Bachate' }},
  { path: '/workpage', component: WorkPage, meta: { title: 'Work - Ravindra Bachate' }},
  { path: '/researchpage', component: ResearchPage, meta: { title: 'Research - Ravindra Bachate' } },
  { path: '/mentorshippage', component: MentorshipPage, meta: { title: 'Mentorship - Ravindra Bachate' } },
  { path: '/resumepage', component: ResumePage, meta: { title: 'Resume - Ravindra Bachate'  }},
  { path: '/contactpage', component: ContactPage, meta: { title: 'Contact - Ravindra Bachate' } },
  { path: '/blogpage', component: BlogPage, meta: { title: 'Blogs - Ravindra Bachate' } },
  { path: '/dggrampage', component: DggramPage, meta: { title: 'Blogs - Ravindra Bachate' } },
  { path: '/apartmentpage', component: ApartmentPage, meta: { title: 'Apartment Case Study - Ravindra Bachate' } },
  { path: '/headerpage', component: HeaderPage },
  { path: '/bottomsheetmodal', component: BottomSheetModal},
  { path: '/floatingheader', component: FloatingHeader },
  { path: '/footerpage', component: FooterPage },
  { path: '/mobileredirect', component: MobileRedirect, meta: { title: 'Home - Ravindra Bachate' }},
];

const router = createRouter({
  history: createWebHistory(),
  routes,
   // Add scroll behavior here
   scrollBehavior(to, from, savedPosition) {
    // Always scroll to the top of the page
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Default Title'; // Set the title
  next();
});

export default router;
